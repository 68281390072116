// css-class-helper
// ===================
// Helper to work with css classes

define(function(require, exports, module) {
  /**
     * Get js prefixes classes
     * Helper function get 'js-' prefixes classes to work with selections in javascript
     * Receive any number of 'strings' and return one big string with all the classes.
     */
  function getJsClasses() {
    return _.chain(arguments)
      .filter(function(item) {
        return _.isString(item) && item.length;
      })
      .map(function(item) {
        return 'js-' + item + ' ' + item;
      })
      .value()
      .join(' ');
  }

  /**
     * Returns the class specified by the "baseClass: plus the "baseClass--modifier"
     * Only returns "baseClass" when modifier is not present
     * @param baseClass base class to work with
     * @param modifier modifier key, can be undefined
     * @returns string with classes ready, baseClass is always present and baseClass--modifier when specified
     */
  function getModifierClass(baseClass, modifier) {
    return getClassName(baseClass, '--', modifier);
  }

  /**
     * Returns the class specified by the "baseClass: plus the "baseClass__element"
     * Only returns "baseClass" when element is not present
     * @param baseClass base class to work with
     * @param element key, can be undefined
     * @returns string with classes ready, baseClass is always present and baseClass__element when specified
     */
  function getElementClass(baseClass, element) {
    return getClassName(baseClass, '__', element);
  }

  function getClassName(baseClass, relationshipSymbol, entityClass) {
    entityClass = entityClass && ' ' + baseClass + relationshipSymbol + entityClass || '';

    return baseClass + entityClass;
  }

  module.exports = {
    getJsClasses,
    getElementClass,
    getModifierClass,
  };
});
