import PropTypes from 'prop-types';
import React from 'react';

const debounceTime = 100;

class LazyLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldLoad: false,
    };

    this.observer = null;
    this.currentElement = null;
    this.disconnectObserver = this.disconnectObserver.bind(this);
    this.setObserver = this.setObserver.bind(this);
    this.load = this.load.bind(this);
  }

  componentDidMount() {
    if (!window.IntersectionObserver) {
      import(
        /* webpackChunkName: "intersection.observer.polyfill" */
        'intersection-observer'
      ).then(this.setObserver);
    } else {
      this.setObserver();
    }
  }

  componentWillUnmount() {
    this.disconnectObserver();
  }

  disconnectObserver() {
    this.observer.disconnect();
  }

  setObserver() {
    const observerOptions = {
      root: null,
      rootMargin: `${this.props.componentProximity}px`,
    };

    this.observer = new IntersectionObserver((entries) => {
      const self = this;

      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0 || entry.isIntersecting) {
          self.load();
        }
      });
    }, observerOptions);
    this.observer.POLL_INTERVAL = debounceTime;
    this.observer.observe(this.currentElement);
  }

  load() {
    this.setState({
      shouldLoad: true,
    });

    this.disconnectObserver();
  }

  render() {
    const { children } = this.props;

    return (
      this.state.shouldLoad && children ? children : <div ref={(ref) => { this.currentElement = ref; }} />
    );
  }
}

LazyLoader.propTypes = {
  componentProximity: PropTypes.number,
};

LazyLoader.defaultProps = {
  componentProximity: 0,
};

export default LazyLoader;
