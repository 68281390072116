import React from 'react';
import { CSSTransition } from 'react-transition-group';

const FadeTransition = props => (
  <CSSTransition
    {...props}
    classNames="fade"
    timeout={500}
  />
);

export default FadeTransition;
