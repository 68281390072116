import PropTypes from 'prop-types';
import React from 'react';
import LazyLoader from 'common/components/lazy-loader-io';

const LazyImage = ({
  imgSrc,
  altText,
  uiClasses,
  componentProximity,
  ...props
}) => (
  <LazyLoader componentProximity={componentProximity}>
    <img src={imgSrc} alt={altText} className={uiClasses} {...props} />
  </LazyLoader>
);

LazyImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  altText: PropTypes.string,
  uiClasses: PropTypes.string,
  componentProximity: PropTypes.number,
};

LazyImage.defaultProps = {
  altText: '',
  uiClasses: '',
  componentProximity: 0,
};

export default LazyImage;
