define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');
  var { each } = require('underscore');

  class Responsive extends React.Component {
    static propTypes = {
      watch: PropTypes.array.isRequired,
    };

    state = {
      base: has('base'),
      small: has('small'),
      medium: has('medium'),
      large: has('large'),
      'x-large': has('x-large'),
    };

    componentDidMount() {
      var self = this;

      // TODO when js loads, there is by default one publish for each breakpoint
      // starting from deepest (base) up to current (x-large in worst case)
      // causing updateState to execute (and render also) for base, small, medium and large
      // when really you only cared about x-large (using the worst case scenario as example)
      each(this.props.watch, function(breakpoint) {
        $.subscribe('breakpoint.' + breakpoint + '.match', self.updateState);
        $.subscribe('breakpoint.' + breakpoint + '.unmatch', self.updateState);
      });
    }

    componentWillUnmount() {
      var self = this;

      each(this.props.watch, function(breakpoint) {
        $.unsubscribe('breakpoint.' + breakpoint + '.match', self.updateState);
        $.unsubscribe('breakpoint.' + breakpoint + '.unmatch', self.updateState);
      });
    }

    updateState = () => {
      this.setState({
        base: has('base'),
        small: has('small'),
        medium: has('medium'),
        large: has('large'),
        'x-large': has('x-large'),
      });
    };

    render() {
      return (
        <>
          {React.cloneElement(this.props.children, { breakpoints: this.state })}
        </>
      );
    }
  }

  module.exports = Responsive;
});
