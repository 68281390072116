define(function(require, exports, module) {
  var cssClassHelper = require('common/util/css-class-helper');
  var Page = require('common/components/promotion/page');
  var PropTypes = require('prop-types');
  var React = require('react');
  var { TransitionGroup } = require('react-transition-group');
  var { default: FadeTransition } = require('common/components/transitions/fade-transition');
  var {
    HashRouter,
    NavLink,
    Redirect,
    Route,
    Switch,
  } = require('react-router-dom');
  var stringUtils = require('common/util/string-utils');
  var { map, assign } = require('underscore');

  function PromotionContent(props) {
    return (
      <div>
        <div className={cssClassHelper.getJsClasses('inner-nav__wrap') + ' qa-promo-nav-wrap'}>
          <div className="inner-nav__scrollable">
            <nav className="inner-nav">
              {props.pages.map(function(page, idx) {
                return (
                  <NavLink
                    activeClassName="is-selected"
                    className={cssClassHelper.getJsClasses('inner-nav__item') + ' qa-promo-nav-item'}
                    key={idx}
                    onClick={props.scrollToContent}
                    to={'/' + stringUtils.slugify(page.title)}
                  >
                    {page.title}
                  </NavLink>
                );
              })}
            </nav>
          </div>
        </div>
        <TransitionGroup className="promo-page__container">
          <FadeTransition appear key={props.location.pathname} exit={false}>
            <div className={cssClassHelper.getJsClasses('promo-page__inner') + ' qa-promo-page-wrap'}>
              <Switch>
                {map(props.pages, function(page, idx) {
                  var pageData = assign({}, {
                    sections: page.sections,
                    type: page.type,
                    getFittingRoomsCovers: props.getFittingRoomsCovers,
                    getRotatorData: props.getRotatorData,
                    tracking: {
                      campaign: props.campaignName,
                      page: idx + '-' + (page.type || ''),
                    },
                  });

                  return (
                    <Route key={idx} path={`${props.match.url}${stringUtils.slugify(page.title)}`} render={() => <Page {...pageData} />} />
                  );
                })}
                <Redirect path="*" to={stringUtils.slugify(props.pages[0].title)} />
              </Switch>
            </div>
          </FadeTransition>
        </TransitionGroup>
      </div>
    );
  }

  class Promotion extends React.Component {
    static propTypes = {
      promotion: PropTypes.object.isRequired,
      getFittingRoomsCovers: PropTypes.func.isRequired,
      getRotatorData: PropTypes.func.isRequired,
      scrollToContent: PropTypes.func.isRequired,
    };

    componentDidMount() {
      $.publish('promotion.pageReady', this);
    }

    render() {
      const {
        getFittingRoomsCovers,
        getRotatorData,
        promotion,
        scrollToContent,
      } = this.props;

      const contentData = {
        getFittingRoomsCovers,
        getRotatorData,
        scrollToContent,
        ...promotion,
      };

      return (
        <HashRouter>
          <Route render={props => <PromotionContent {...contentData} {...props} />} />
        </HashRouter>
      );
    }
  }

  module.exports = Promotion;
});
