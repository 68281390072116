define(function() {
  function getComputedValue(element, prop) {
    if (!element) { return; }
    var styles = getComputedStyle(element);

    return styles[prop];
  }

  function getElementHeight(element, withMargin) {
    if (!element) { return 0; }

    var height = element.offsetHeight;

    if (withMargin) {
      var style = getComputedStyle(element);

      height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    }

    return height;
  }

  // TODO review this method, document.body.scrollTop appears to always be 0
  function getElementOffset(element) {
    if (!element) {
      return null;
    }
    var rect = element.getBoundingClientRect();

    return {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft,
    };
  }

  function getElementPosition(element) {
    if (!element) {
      return null;
    }
    const { top, left } = element.getBoundingClientRect();
    const { scrollTop, scrollLeft } = document.documentElement;

    return {
      top: scrollTop + top,
      left: scrollLeft + left,
    };
  }

  function getElementWidth(element, withMargin) {
    if (!element) { return 0; }

    var width = element.offsetWidth;

    if (withMargin) {
      var style = getComputedStyle(element);

      width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
    }

    return width;
  }

  function getScreenHeight() {
    return (window.innerHeight > 0) ? window.innerHeight : screen.height;
  }

  function getScreenWidth() {
    return (window.innerWidth > 0) ? window.innerWidth : screen.width;
  }

  return {
    getComputedValue,
    getElementHeight,
    getElementOffset,
    getElementPosition,
    getElementWidth,
    getScreenHeight,
    getScreenWidth,
  };
});
