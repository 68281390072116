import { isEmpty } from 'underscore';
import { getDefaultPliParams, doRequest } from 'common/util/bc.productapi.request';
import ajaxHelper from 'common/util/ajax-helper';

const productApiParams = getDefaultPliParams();
const MAX_PRODUCTS = 20;

function chunk(productIds, chunkSize) {
  const length = productIds.length;
  const response = [];

  for (let i = 0; i < length; i += chunkSize) {
    response.push(productIds.slice(i, i + chunkSize));
  }

  return response;
}

function transformToPLI(data) {
  data = data || {};
  const productSummary = data.metadata && data.metadata.productSummary;
  const products = data.products || [];

  if (!isEmpty(productSummary)) {
    products.forEach((product) => {
      product.summary = productSummary[product.id];
    });
  }

  return products;
}

function fetchProductsConcurrent(productIds, params = productApiParams) {
  if (isEmpty(productIds)) {
    return Promise.resolve([]);
  } else {
    const promisedProducts = chunk(productIds, MAX_PRODUCTS)
      .map(productIdsChunk => (
        new Promise((resolve) => {
          doRequest(productIdsChunk.join(','), params, 'products')
            .then((productResponse) => {
              resolve(transformToPLI(productResponse));
            })
            .fail((err) => {
              $.error(`[Error] product api request failed for product ids ${JSON.stringify(productIds)}
              - statusCode: ${err.status} - statusText: ${err.statusText}  - response: ${err.responseText}`,
              'product-service');

              resolve([]);
            });
        })
      ));

    return Promise.all(promisedProducts)
      .then((returnedProducts) => {
        let products = [];

        returnedProducts.forEach((promisedProduct) => {
          products = products.concat(promisedProduct);
        });

        return products;
      });
  }
}

function fetchProducts(productIds, apiParams = productApiParams) {
  const promisedProducts = productIds.map(productId => (
    new Promise((resolve) => {
      doRequest(productId, apiParams, 'products')
        .then((productResponse) => {
          resolve(transformToPLI(productResponse));
        })
        .fail((err) => {
          $.error(
            `[Error] product api request failed for product id ${productId}
        - statusCode: ${err.status} - statusText: ${err.statusText}  - response: ${err.responseText}`,
            'product-service',
          );

          resolve([]);
        });
    })
  ));

  return Promise.all(promisedProducts)
    .then((returnedProducts) => {
      let products = [];

      returnedProducts.forEach((promisedProduct) => {
        products = products.concat(promisedProduct);
      });

      return products;
    });
}

let productNotes = [];
let productNotesPromise;

function setProductNotesPromise(promiseValue, responseValue) {
  productNotesPromise = promiseValue;
  productNotes = responseValue;
}

function getNotes() {
  if (productNotes.length > 0) {
    return Promise.resolve(productNotes);
  }

  if (productNotesPromise !== undefined) {
    return productNotesPromise;
  }

  productNotesPromise = new Promise((resolve) => {
    ajaxHelper.doJsonRequest({
      type: 'GET',
      cache: true,
      url: '/v1/production/targeters/store_product_bcs_productnotes',
      data: {
        siteId: BC.site.id,
      },
    }, 'common/util/pli-helper.js').then((response) => {
      productNotes = response.length && response[0].jsonData;
      resolve(productNotes);
    }).fail((err) => {
      $.error(
        `[Error] product notes request failed
        - statusCode: ${err.status} - statusText: ${err.statusText}  - response: ${err.responseText}`,
        'product-service',
      );
      resolve({});
    });
  });

  return productNotesPromise;
}

export {
  fetchProducts,
  getNotes,
  fetchProductsConcurrent,
  setProductNotesPromise,
};
