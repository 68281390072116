define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');
  var Slider = require('common/components/slider');
  var PLI = require('common/components/promotion/pli/base');
  var Info = require('common/components/promotion/pli/info');
  var SkuPricing = require('common/components/promotion/pli/sku-pricing');
  var { default: BulletPoints } = require('common/components/promotion/pli/bullet-points');
  var DiscountTag = require('common/components/promotion/pli/discount-tag');
  var stringUtils = require('common/util/string-utils');
  var { default: LazyImage } = require('common/components/lazy-loader-io/lazy-image');
  var { default: TrackingContext } = require('common/components/tracking-context');
  var {
    bind,
    contains,
    isEmpty,
    toArray,
    map,
    partial,
  } = require('underscore');

  var GEARHEAD_PICK_TAG = 'Gearhead Pick';

  var ProductTags = function(props) {
    return (
      <div>
        {contains(props.tags, GEARHEAD_PICK_TAG) &&
        <img
          alt=""
          className="pli__ghp-tag js-ghp-tag"
          src="/images/bcs/ghx/Badge_Pick-50.png"
          srcSet="/images/bcs/ghx/Badge_Pick-50.png,
                    /images/bcs/ghx/Badge_Pick-50@2x.png 2x,
                    /images/bcs/ghx/Badge_Pick-50@3x.png 3x"
        />
        }
        {props.selectedVariant.discountPercent > 0 && <DiscountTag {...props.selectedVariant} />}
      </div>
    );
  };

  var ProductDetails = function(props) {
    return (
      <div className="rotator__content js-rotator-content" ref={props.setRef}>
        <Info {...props} />
        <PLI {...props}>
          <ProductTags {...props} />
        </PLI>
      </div>
    );
  };

  var RotatorDetails = function(props) {
    return (
      <div className="rotator__bullets">
        <h4 className="rotator__details-title">{$.t('details')}</h4>
        {props.children }
      </div>
    );
  };

  var RotatorPlaceholder = function() {
    return (
      <div className="rotator-placeholder">
        <div className="rotator-placeholder-section rotator-placeholder__details" />
        <div className="rotator-placeholder-section rotator-placeholder__img" />
        <div className="rotator-placeholder-section rotator-placeholder__carousel" />
      </div>
    );
  };

  var Thumb = function(props) {
    var activeClass = props.isActive ? ' thumbs__item--active' : '';

    return (
      <div
        className={'thumbs__item js-rotator-thumb' + activeClass}
        onClick={partial(props.handleClick, props.index, null)}
        ref={props.setRef}
      >
        <LazyImage
          componentProximity={100}
          altText={props.product.title}
          imgSrc={'//content.backcountry.com' + props.product.selectedVariant.image.small}
          uiClasses="thumbs__image"
        />
      </div>
    );
  };

  class Rotator extends React.Component {
    static contextType = TrackingContext;

    static propTypes = {
      breakpoints: PropTypes.object,
      getData: PropTypes.func.isRequired,
      onCollapse: PropTypes.func.isRequired,
      id: PropTypes.string.isRequired,
      showCta: PropTypes.bool,
      url: PropTypes.string,
    };

    static defaultProps = {
      breakpoints: {},
      showCta: false,
      url: '',
    };

    state = {
      active: 0,
      dataLoaded: false,
      lastLargeBreakpoint: this.props.breakpoints.large,
    };

    componentDidMount() {
      var self = this;

      this.props.getData(this.props.id)
        .then(function(products) {
          if (isEmpty(products)) {
            self.setErrorState();
            self.props.onCollapse();
          } else {
            self.setState({
              dataLoaded: true,
              products: toArray(products),
            });
          }
        }, function() {
          self.setErrorState();
          self.props.onCollapse();
        });
    }

    static getDerivedStateFromProps(props, state) {
      if (props.breakpoints.large !== state.lastLargeBreakpoint) {
        return {
          active: 0,
          lastLargeBreakpoint: props.breakpoints.large,
        };
      }

      return null;
    }

    setErrorState = () => {
      this.setState({
        hasFailed: true,
      });
    };

    updateActive = (index, direction) => {
      this.setState({
        active: index,
      });

      let eventOrigin = 'carouselClick';

      if (direction) {
        eventOrigin = direction === 'forward' ? 'arrowForward' : 'arrowBackward';
      }

      this.context.trackEvent({ action: eventOrigin });
    };

    render() {
      const trackingContext = this.context;
      var breakpoints = this.props.breakpoints;
      var title = this.props.title;
      var url = this.props.url;
      var handleChange = bind(this.updateActive, this);
      var showCta = this.props.showCta;

      return (
        !this.state.hasFailed &&
        <div className="promotion__rotator qa-promo-rotator">
          {this.state.dataLoaded ?
            <React.Fragment>
              <Slider
                {...this.props}
                fullWidth={false}
                loop
                onChangingSlide={handleChange}
                selected={this.state.active}
                showArrows={breakpoints.large && this.state.products.length > 1}
                uiModifier="rotator"
              >
                {map(this.state.products, (product) => {
                  const productUrl = product.selectedVariant.url;
                  const isOOS = product.availabilityStatus.indexOf('OutOfStock') > -1;
                  const shouldDisplayDetails = product.bulletPoints || product.shortDescription;

                  product.selectedVariant.url = trackingContext.buildFullUrl(productUrl, { action: 'pdp', productId: product.id });

                  return (
                    <ProductDetails {...product} key={product.id}>
                      {isOOS ?
                        <span className="rotator__oos js-rotator-oos">{$.t('label.product.outOfStock')}</span> :
                        <SkuPricing {...product.selectedVariant} />
                      }
                      {breakpoints.large &&
                      <div className="rotator__details">
                        <div className="rotator__bullets">
                          {shouldDisplayDetails &&
                          <RotatorDetails {...product}>
                            {product.bulletPoints ?
                              <BulletPoints {...product} /> :
                              <p className="pli__short-description js-short-description">{stringUtils.truncateAndSuffix({ str: product.shortDescription, maxLength: 430 })}</p>
                            }
                          </RotatorDetails>
                          }
                        </div>
                        <div className="rotator__cta">
                          <a
                            className="btn btn-primary rotator__cta-button"
                            href={trackingContext.buildFullUrl(productUrl, { action: 'moreDetails' })}
                          >
                            {isOOS ? $.t('label.shop.similar') : $.t('view.details')}
                          </a>
                          {showCta &&
                            <a
                              className="btn-secondary rotator__cta-button"
                              href={trackingContext.buildPromotionUrl(url, { action: 'shopAll' })}
                            >
                              {$.t('shop.all.title', title)}
                            </a>
                          }
                        </div>
                      </div>
                      }
                    </ProductDetails>
                  );
                })}
              </Slider>
              {breakpoints.large && this.state.products.length > 1 &&
                <Slider
                  fullWidth={breakpoints.large}
                  ignoreExternal
                  pageSize={13}
                  selected={this.state.active}
                  showArrows={this.state.products.length > 13}
                  uiModifier="thumbnails"
                >
                  {map(this.state.products, (product, index) => (
                    <Thumb
                      handleClick={handleChange}
                      index={index}
                      isActive={index === this.state.active}
                      key={product.id}
                      product={product}
                    />
                  ))}
                </Slider>
              }
            </React.Fragment> :
            <RotatorPlaceholder {...breakpoints} />
          }
        </div>
      );
    }
  }

  module.exports = Rotator;
});
