import {
  filter,
  has,
  isEmpty,
} from 'underscore';
import gramsTo from 'common/util/weight-helper';

function isSkuAvailable(sku) {
  var status = sku && sku.availability && sku.availability.status;

  return status === 'InStock' || status === 'Backorderable';
}

function getAvailableSkus(skus) {
  var availableSkus = filter(skus, isSkuAvailable);

  return isEmpty(availableSkus) ? skus : availableSkus;
}

/**
   * A sku identifier is composed by three parts -product, color, and size- separated by a dash sign «-», e.g. «TNF03JL-TNFBK-XL».
   * This function returns an object containig the three keys that compose a sku id.
   */
function getKeys(skuId) {
  if (!skuId) {
    return {};
  }

  var skuParts = skuId.split('-');

  return {
    product: skuParts[0],
    color: skuParts[1] || '',
    size: skuParts[2] || '',
  };
}

/**
   * Returns the zero-index position of a sku id within skus array
   * -1 if is not found
   */
function getSkuIndex(skuId, skus) {
  var skuIndex;

  if (isEmpty(skuId) || isEmpty(skus)) {
    skuIndex = -1;
  } else {
    skuIndex = skus.findIndex(function(sku) {
      return sku.id === skuId;
    });
  }

  return skuIndex;
}

function getSkuWeight(sku) {
  const weightAttribute = (sku.attributes || []).find(attribute => attribute.name === 'weight') || {};

  return Number(weightAttribute.value);
}

function getSkusActualWeight(skus, isBike) {
  let hasAtLeast1SkuWithValidWeight = false;
  const skusWithValidWeight = {};
  const skusWithWeight = (skus || []).map((sku) => {
    const skuWeight = getSkuWeight(sku);
    const isWeightValid = !!skuWeight && skuWeight > 0;
    const skuSizeName = isBike ? getSkuSizeName(sku) : sku.title;

    hasAtLeast1SkuWithValidWeight = hasAtLeast1SkuWithValidWeight || isWeightValid;
    skusWithValidWeight[skuSizeName] = skusWithValidWeight[skuSizeName] || isWeightValid;

    return {
      title: skuSizeName,
      actualWeight: isWeightValid ? { g: skuWeight + 'g', oz: gramsTo(skuWeight, 'oz') } : {},
    };
  });

  if (!hasAtLeast1SkuWithValidWeight) return [];

  if (!isBike) return skusWithWeight;

  const addedSkus = {};

  return skusWithWeight.filter(({ title, actualWeight }) => {
    const keepSku = !addedSkus[title] && (!skusWithValidWeight[title] || !isEmpty(actualWeight));

    if (keepSku) addedSkus[title] = true;

    return keepSku;
  });
}

function getSkuSizeName(sku) {
  if (has(sku, ['size', 'name'])) {
    return sku.size.name;
  }

  if (has(sku, ['color', 'name'])) {
    return sku.title.replace(sku.color.name + ',', '').trim();
  }

  return sku.title;
}

export {
  isSkuAvailable,
  getAvailableSkus,
  getKeys,
  getSkusActualWeight,
  getSkuIndex,
};
