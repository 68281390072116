const weightUnitsInGrams = {
  kg: 0.001,
  lbs: 0.00220462,
  oz: 0.035274,
};

function gramsTo(grams, toUnit) {
  const convertGramsTo = toUnit => ((grams * weightUnitsInGrams[toUnit]).toFixed(2) + toUnit);

  if (Array.isArray(toUnit)) {
    return toUnit.reduce((accumulator, toUnit) => {
      accumulator[toUnit] = convertGramsTo(toUnit);

      return { ...accumulator };
    }, {});
  } else {
    return convertGramsTo(toUnit);
  }
}

export default gramsTo;
