define(function(require) {
  var BaseController = require('common/controllers/base');
  var fittingRoomService = require('common/services/fitting-room-service');
  var Promotion = require('common/components/promotion');
  var ReactUtils = require('common/components/utils');
  var inUrl = require('common/util/bc.url.utils').inUrl;
  var productReducer = require('common/util/reducers/promotion/products');
  var { clone, each } = require('underscore');

  require('common/widgets/bc.lazyloader');

  var scrolled = false;

  var PromotionController = BaseController.extend({
    _selectors: {
      promoContent: '.js-promo-page',
      promoHero: '.js-promotion-intro-wrap',
    },
    options: {},
    initialize() {
      var self = this;

      $.subscribe('promotion.domReady', function(promoData) {
        self.initPromoPage(promoData);
      });
    },
    loaded() {
      if (inUrl('autoScroll') === 'true') {
        this.scrollToContent();
      }
    },
    ready() {
      var self = this;

      $(self._selectors.promoHero).lazyloader()
        .lazyloader('load');
    },
    initPromoPage(promoData) {
      var self = this;

      self.promotionData = clone(promoData);

      $.subscribe('promotion.pageReady', function() {
        each(self.promotionData.js, function(fileUrl) {
          var script = document.createElement('script');

          script.type = 'text/javascript';
          script.src = fileUrl;

          $('body').append(script);
        });
      });

      ReactUtils.renderIntoElement(
        Promotion,
        $('.js-promo-page').get(0),
        {
          promotion: self.promotionData,
          getFittingRoomsCovers: fittingRoomService.fetchRoomsCovers,
          getRotatorData: self._getRotatorData.bind(self),
          scrollToContent: self.scrollToContent.bind(self),
        },
      );
    },
    scrollToContent() {
      if (!scrolled) {
        var self = this;

        $('html, body').animate({
          scrollTop: $(self._selectors.promoContent).offset().top,
        }, 500);
        scrolled = true;
      }
    },
    _getRotatorData(fittingRoomId) {
      return fittingRoomService.fetchRoom(fittingRoomId)
        .then(function(data) {
          return productReducer.reduceRoom(data);
        });
    },
  });

  return PromotionController;
});
