define(function(require) {
  var config = {
    host() {
      var { envName: environment } = require('common/config/environment');

      /* istanbul ignore next line */
      if (environment === 'production') {
        return has('listServiceBackup') ? '//list-service-api-usw2.prod.gcp.bcinfra.net' : '//list-service-api.prod.gcp.bcinfra.net';
      } else if (environment === 'gcp-test') {
        return '//list-service-api.test.gcp.bcinfra.net';
      } else if (environment === 'gcp-prod') {
        return has('listServiceBackup') ? '//list-service-api-usw2.prod.gcp.bcinfra.net' : '//list-service-api.prod.gcp.bcinfra.net';
      } else if (environment === 'gcp-stage') {
        return '//list-service-api.stage.gcp.bcinfra.net';
      } else if (environment === 'development') {
        return 'http://localhost:9000';
      } else {
        return '//list-service-api.test.gcp.bcinfra.net';
      }
    },
    timeout: 10000,
    apiFields: 'fields=id,skus.id,skus.image.url,skus.color.family',
  };

  function doRequest(opts) {
    opts.cache = true;
    opts.timeout = config.timeout;

    return $.ajax(opts).fail(function(jqXHR, textStatus, errorThrown) {
      var responseJson = jqXHR.responseJSON || '{}';

      $.error('textStatus: ' + textStatus + ' errorThrown: ' + errorThrown +
                ' serviceUrl: ' + opts.url + ' readyState: ' + jqXHR.readyState +
                ' status: ' + jqXHR.status + ' responseText: ' + jqXHR.responseText +
                ' responseJSON: ' + JSON.stringify(responseJson), 'common/services/fitting-room-service.js');
    });
  }

  function filtersToQuery(filters) {
    var keys = Object.keys(filters);

    return keys.reduce(function(acc, key, index) {
      acc += index === 0 ? '' : '%7C';

      return acc + key + ':' + filters[key];
    }, '');
  }

  function fetchRooms(filters = {}, pagination = '') {
    filters.listType = 'fitting-room-kit';
    var sort = 'sort=creationDate:desc';
    var qFilters = filtersToQuery(filters);

    return doRequest({
      url: makeUrl('/list-service/search?filters=' + qFilters + '&' + sort + pagination),
      method: 'GET',
    });
  }

  function fetchUsers(userType) {
    return doRequest({
      url: makeUrl(`/list-service/users/type/${userType}`),
      method: 'GET',
    });
  }

  function fetchRoom(id) {
    return doRequest({
      url: '/services/fitting-room/products?id=' + id,
      method: 'GET',
    });
  }

  function fetchRoomsCovers(ids) {
    return doRequest({
      url: '/services/fitting-room/covers?ids=' + ids,
      method: 'GET',
    });
  }

  function fetchMarkdownRoom(id) {
    return doRequest({
      url: '/services/fitting-room/products?id=' + id + '&parseNotes=true',
      method: 'GET',
    });
  }

  function makeUrl(endpoint) {
    return config.host() + endpoint;
  }

  return {
    fetchRooms,
    fetchRoom,
    fetchUsers,
    fetchMarkdownRoom,
    fetchRoomsCovers,
  };
});
