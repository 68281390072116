define([], function() {
  var defaultSiteCode = 'bcs';
  var hosts = {
    dev: '//test.backcountry.com',
    test: '//test.backcountry.com',
    stage: '//stage.backcountry.com',
    prod: '//api.backcountry.com',
  };
  var env = {
    dev: [
      'integration',
      'demo',
      'dev',
    ],
    test: ['test'],
    stage: ['stage'],
    pqa: ['pqa'],
    prod: ['www'],
  };
  var containsAny = function(string, substrings) {
    for (var i = 0; i < substrings.length; i++) {
      if (string.indexOf(substrings[i]) > -1) {
        return true;
      }
    }

    return false;
  };

  var productApi = {
    getHostname() {
      return window.location.hostname;
    },
    init() {
      this.setHost();
      this.setPreview();
      this.setSite();
    },
    hosts,
    makeParams(params) {
      params = params || {};
      params.site = params.site || this.site;
      params.preview = typeof params.preview === 'undefined' ? this.preview : params.preview;

      var paramStr = '';

      for (var key in params) {
        paramStr += '&' + key + '=' + params[key];
      }

      return paramStr.slice(1);
    },
    reset() {
      this.host = undefined;
      this.preview = undefined;
      this.site = undefined;
    },
    setHost() {
      var host = hosts.prod;
      var hostname = this.getHostname();

      if (BC.apiDomain) {
        host = BC.apiDomain;
      } else if (!containsAny(hostname, env.prod)) {
        if (containsAny(hostname, env.dev) || (has && has('dev'))) {
          host = hosts.dev;
        } else if (containsAny(hostname, env.test)) {
          host = hosts.test;
        } else if (containsAny(hostname, env.stage)) {
          host = hosts.stage;
        }
      }

      this.host = host;
    },
    setPreview() {
      this.preview = this.getHostname().indexOf('preview') > -1;
    },
    setSite() {
      this.site = BC && BC.site && BC.site.code || defaultSiteCode;
    },
  };

  productApi.init();

  return productApi;
});
