define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');
  var Subsections = require('common/components/promotion/subsections');
  var Responsive = require('common/components/responsive');
  var Rotator = require('common/components/promotion/rotator');
  var cssClassHelper = require('common/util/css-class-helper');
  var translate = require('common/localization').translate;
  var { assign } = require('underscore');

  class PromotionSection extends React.Component {
    static propTypes = {
      section: PropTypes.object.isRequired,
      type: PropTypes.string.isRequired,
      getFittingRoomsCovers: PropTypes.func.isRequired,
      idx: PropTypes.number.isRequired,
    };

    state = {
      hasFailed: false,
    };

    setErrorState = () => {
      this.setState({
        hasFailed: true,
      });
    };

    render() {
      var self = this;
      var props = this.props;
      var state = this.state;

      var subsectionData = assign({}, {
        subsections: props.section.subsections,
        type: props.type,
        getFittingRoomsCovers: props.getFittingRoomsCovers,
      });

      var rotatorData = assign({}, {
        id: props.section.fittingRoomId,
        getData: props.getRotatorData,
        onCollapse: self.setErrorState,
        showCta: true,
        title: props.section.title,
        url: props.section.baseUrl,
        tracking: props.tracking,
      });

      return (
        (!state.hasFailed || props.section.subsections) &&
        <div id={'promotion__section-' + props.idx} className={cssClassHelper.getJsClasses('promotion__section') + ' qa-promo-section'}>
          <h1 className={cssClassHelper.getJsClasses('promotion__section-title--main') + ' qa-promo-section-title'}>{props.section.title}</h1>
          <a className="btn-secondary" href={props.section.baseUrl}>{translate('shop.all.title', props.section.title)}</a>
          {props.section.subsections && <Subsections {...subsectionData} />}
          {!state.hasFailed && <h3 className="promotion__section-title">{translate('shop.best.deals')}</h3>}
          {props.section.rotator &&
          <Responsive watch={['large']}>
            <Rotator {...rotatorData} />
          </Responsive>
          }
        </div>
      );
    }
  }

  module.exports = PromotionSection;
});
