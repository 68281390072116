define(function(require) {
  const {
    includes, findWhere, map, reject, extend, defaults, find, isFunction, uniq, chain,
  } = require('underscore');
  const skuHelper = require('common/util/sku-helper');
  const addParam = require('common/util/bc.url.utils').addParam;
  const componentTrackingHelper = require('common/util/component-tracking-helper');
  const { pages } = require('common/util/hd-images-pli-white-list.json');
  const productService = require('common/services/product-service');

  /**
   *
   * Returns sku URL adding 'fskid' parameter with skuId and 'ti' and INT_ID parameters
   * if tracking data is complete
   */
  const buildSkuUrl = (skuUrl, skuId, trackingOpts) => componentTrackingHelper.buildFullUrl(addParam(skuUrl, 'fskid', skuId, true), trackingOpts);

  const buildImages = (image) => {
    const imgRegex = /medium/;
    const imageHost = '//content.' + BC.site.domain;

    image = imageHost + image;

    return {
      nineHundred: image.replace(imgRegex, '900'),
      large: image.replace(imgRegex, 'large'),
      medium: image,
      mobile: image.replace(imgRegex, '160'),
      tiny: image.replace(imgRegex, 'tiny'),
    };
  };

  const isAvailable = (product) => {
    const availabilityStatus = product.availabilityStatus;
    const isInStock = availabilityStatus === 'InStock';
    const isBackorderable = availabilityStatus === 'Backorderable';

    return has('backorder') ? isInStock || isBackorderable : isInStock;
  };

  const isTagIncluded = (product, tag) => includes(product.tags, tag);

  const byId = item => item.id;

  const byColorFamily = item => item.family;

  const byPosition = item => item.position;

  /* Different image sizes are needed in some pages because the PLIs have different sizes
    * and the images were blurred
    */
  const getImageUrl = (images, features, selectedSizeImage) => {
    if (pages.includes(BC.page.type)) {
      if (selectedSizeImage) {
        return images[selectedSizeImage] || images.medium.replace('medium', selectedSizeImage);
      }

      return images.medium;
    } else {
      return features.mobile ? images.mobile : images.medium;
    }
  };

  const getPLIData = (productData, tracking) => {
    const { products, metadata = {}, isConstructorData = false } = productData;
    const { productSummary } = metadata;
    const product = products ? products[0] : productData;

    if (isConstructorData) {
      return productData;
    }

    if (productSummary && productSummary[product.id]) {
      product.summary = productSummary[product.id];
    }

    const availableSkus = skuHelper.getAvailableSkus(product.skus);
    const credits = findWhere(product.features, { name: 'storecredits' });
    const creditsAmount = credits && parseInt(credits.value, 10) || 0;
    const techSpecs = reject(product.features, feature => feature.name === 'storecredits');
    const reviews = product.customerReviews;
    const freeGift = product.freeGifts && product.freeGifts[BC.site.code];
    const alternatives = {};
    const colors = [];
    const sizes = [];

    const processedSkus = map(availableSkus, (sku) => {
      const images = buildImages(sku.image.url);
      const skuKeys = skuHelper.getKeys(sku.id);
      const alternative = alternatives[skuKeys.color] || [];

      alternatives[skuKeys.color] = alternative.concat(skuKeys.size);
      colors.push({
        id: skuKeys.color,
        name: sku.color && sku.color.name || '',
        family: sku.color && sku.color.family || '',
        imageUrl: images.tiny,
      });

      if (sku.size) {
        sizes.push({
          id: skuKeys.size,
          name: sku.size.name,
          position: sku.size.position,
        });
      }

      return extend({
        images,
        fullUrl: buildSkuUrl(product.url || sku.url, sku.id, defaults({ productId: skuKeys.product }, tracking)),
      }, sku);
    });

    if (reviews && !reviews.starRating && reviews.average) {
      reviews.starRating = reviews.average.toString().replace('.', '-');
    }

    return extend({
      alternatives,
      colors: uniq(colors, byId),
      familyColors: uniq(colors, byColorFamily),
      creditsAmount,
      hasFreeGift: !!freeGift,
      isAvailable: isAvailable(product),
      isExclusive: isTagIncluded(product, 'Exclusive'),
      isGearHeadPick: isTagIncluded(product, 'Gearhead Pick'),
      sizes: chain(sizes).uniq(byId)
        .sortBy(byPosition)
        .value(),
    }, product, {
      features: techSpecs,
      skus: processedSkus,
    });
  };

  const withKeys = sku => extend({ keys: skuHelper.getKeys(sku.id) }, sku);

  const getSkuByColor = (product, color) => {
    const colorSku = find(product.skus, sku => sku.id && sku.id.indexOf(`-${color}-`) !== -1);
    const sku = colorSku || product.skus && product.skus[0];

    return sku && withKeys(sku) || {};
  };

  const getSkuByColorFamily = (product, color) => {
    const colorSku = find(product.skus, sku => sku.color && sku.color.family === color);
    const sku = colorSku || product.skus && product.skus[0];

    return sku && withKeys(sku) || {};
  };

  const getSkuById = (product, skuId) => {
    const idSku = find(product.skus, sku => sku.id === skuId);
    const sku = idSku || product.skus && product.skus[0];

    return sku && withKeys(sku) || {};
  };

  const lastknown = (arr, key) => {
    if (arr[0] && arr[0][key]) {
      return lastknown(arr[0][key], key);
    } else {
      return arr[0];
    }
  };

  const onStockStatus = (product, callbackObject) => {
    const availabilityStatus = (product.availability && product.availability.status) || product.availabilityStatus;
    const func = callbackObject['on' + availabilityStatus];

    return isFunction(func) ? func() : availabilityStatus;
  };

  const initPliInteractions = ({
    pliFocusables,
    pliWrap,
  }) => {
    $(document)
      .on('focus', pliFocusables, ({ target }) => {
        const pli = target.closest(pliWrap);

        pli.classList.add('is-expanded');
      })
      .on('blur', pliFocusables, ({ target }) => {
        setTimeout(() => {
          const pli = target.closest(pliWrap);

          if (!pli.contains(document.activeElement)) {
            pli.classList.remove('is-expanded');
          }
        }, 0);
      });
  };

  const setTiParam = (url, value) => {
    const key = 'ti';
    let encodedValue;

    try {
      encodedValue = btoa(value);
    } catch (e) {
      $.error('[pli-helper] setTiParam could not encode ' + value);
    }

    if (!encodedValue) {
      return url;
    }

    return addParam(url, key, encodedValue, true);
  };

  const stockBasedUrlHelper = (product, skuIndex, appendOptions) => {
    const productconstiant = product.skus[skuIndex] || product.skus[0];
    const productUrl = productconstiant.url + '?skid=' + productconstiant.id;
    const onInStock = function() {
      return productUrl + ((appendOptions && appendOptions.inStock) || '');
    };

    const stockOptions = {
      onInStock,
      onLowStock: onInStock,
      onOutOfStock: onInStock,
      onPermanentlyOutOfStock: onInStock,
      onBackorderable: onInStock,
    };

    return onStockStatus(productconstiant, stockOptions);
  };

  const getPLINotes = (notes, productId) => productService.getNotes().then((productNotes) => {
    if (productNotes[productId]) {
      return [productNotes[productId], ...notes];
    }

    return notes;
  });

  return {
    getImageUrl,
    getPLIData,
    getPLINotes,
    getSkuByColor,
    getSkuByColorFamily,
    getSkuById,
    initPliInteractions,
    lastknown,
    onStockStatus,
    setTiParam,
    stockBasedUrlHelper,
  };
});
