define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');

  class Info extends React.Component {
    static propTypes = {
      brand: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      title: PropTypes.string.isRequired,
    };

    render() {
      return (
        <div className="pli__info js-info">
          <h3 className="pli__full-title">
            <span className="pli__brand js-brand">{this.props.brand.name}</span>
            <span className="pli__title js-title">{this.props.title}</span>
          </h3>
          {this.props.children}
        </div>
      );
    }
  }

  module.exports = Info;
});
