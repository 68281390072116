define(['common/util/bc.productapi', 'underscore'], function(productApiUrlBuilder, { isUndefined, isObject, isEmpty }) {
  // TODO move this into a service
  var requestBuilder = {
    doRequest(id, params, endpoint, version) {
      var misconfiguredParams = verifyParams(id, params);

      if (misconfiguredParams) {
        return new $.Deferred().reject('misconfigured params');
      }

      return $.ajax({
        url: buildUrl(id, params, endpoint, version),
        method: 'GET',
        cache: true,
      });
    },
    getDefaultPliParams() {
      return {
        fields: 'id,title,freeGifts,brand,customerReviews,availabilityStatus,features,tags,detailImages,notes,skus',
        metadata: 'found,productSummary',
      };
    },
  };

  function verifyParams(id, params) {
    if (isUndefined(id) || !isObject(params) || isEmpty(params)) {
      $.error('[bc.productapi.request] misconfigured params');

      return true;
    }
  }
  function buildUrl(id, params, endpoint, version) {
    endpoint = isUndefined(endpoint) ? 'products' : endpoint;
    version = isUndefined(version) ? 'v1' : version;

    var productIdParam = (endpoint !== 'suggestions' && endpoint !== 'current-steals') ? ('/' + id) : '';

    return productApiUrlBuilder.host + '/' + version + '/' + endpoint +
          productIdParam +
          '?' + productApiUrlBuilder.makeParams(params);
  }

  return requestBuilder;
});
