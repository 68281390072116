define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');

  class DiscountTag extends React.Component {
    static propTypes = {
      discountPercent: PropTypes.number.isRequired,
    };

    render() {
      return (
        <div className="pli__discount-tag js-discount-tag">
          <div className="pli__discount-background" />
          <span className="pli__discount-value js-discount-value">{this.props.discountPercent}</span>
          <div className="pli__discount-suffix js-discount-suffix">
            <span className="pli__discount-percent js-discount-percent">%</span>
            <span className="pli__discount-off js-discount-off">{$.t('label.off')}</span>
          </div>
        </div>
      );
    }
  }

  module.exports = DiscountTag;
});
