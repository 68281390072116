import PropTypes from 'prop-types';
import React from 'react';

function BulletPoints(props) {
  return (
    <ul className="pli__bullets">
      {props.bulletPoints.map((bulletPoint, index) => (
        <li className="pli__bullet" key={index}>{bulletPoint}</li>
      ))}
    </ul>
  );
}

BulletPoints.propTypes = {
  bulletPoints: PropTypes.array.isRequired,
};

export default BulletPoints;
