define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');
  var getModifierClass = require('common/util/css-class-helper').getModifierClass;
  var getElementWidth = require('common/util/dom-utils').getElementWidth;
  const { translate } = require('common/localization');
  const { debounce } = require('underscore');

  function checkIndex(index, actualSlides, loop) {
    if (index < 0) {
      index = loop ? actualSlides - 1 : 0;
    } else if (index >= actualSlides) {
      index = loop ? 0 : actualSlides - 1;
    }

    return index;
  }

  function actualSlides(props) {
    return Math.ceil(props.children.length / props.pageSize);
  }

  class Slider extends React.Component {
    static propTypes = {
      animated: PropTypes.bool,
      fullWidth: PropTypes.bool,
      loop: PropTypes.bool,
      ignoreExternal: PropTypes.bool,
      onChangingSlide: PropTypes.func,
      onSlideAnimationEnd: PropTypes.func,
      pageSize: PropTypes.number,
      selected: PropTypes.number,
      showArrows: PropTypes.bool,
      showDots: PropTypes.bool,
      uiModifier: PropTypes.string,
      a11yArrows: PropTypes.bool,
      showOnlyRightArrow: PropTypes.bool,
    };

    static defaultProps = {
      animated: true,
      fullWidth: true,
      loop: false,
      ignoreExternal: false,
      pageSize: 1,
      selected: 0,
      showArrows: false,
      showDots: false,
      uiModifier: '',
      a11yArrows: false,
      showOnlyRightArrow: false,
    };

    constructor(props) {
      super(props);
      this.slider = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
      if (
        !prevProps.ignoreExternal &&
        (prevProps.selected !== this.props.selected || this.state.prevIndex !== prevState.index)
      ) {
        this.resetScroll();
      }
      if (prevProps.selected === this.props.selected) {
        this.slider.current.scrollLeft += 0;
      }
      if (this.props.selected > prevProps.selected) {
        this.slider.current.scrollLeft += this.slider.current.offsetWidth / this.props.children.length;
      }
      if (this.props.selected < prevProps.selected) {
        this.slider.current.scrollLeft -= this.slider.current.offsetWidth / this.props.children.length;
      }
    }

    componentDidMount() {
      this.forceUpdate();
    }

    resetScroll = () => {
      const newIndex = checkIndex(this.props.selected, actualSlides(this.props), this.props.loop);

      if (this.containerElement.scrollLeft !== 0) {
        this.containerElement.scrollLeft = 0;
      }

      this.setState({
        index: newIndex,
        prevIndex: newIndex,
      });
    };

    getPaginationDots = () => {
      var dots = [];
      var actualSlide = this.state.index;
      var actualSlidesQuantity = actualSlides(this.props);

      for (var i = 0; i < actualSlidesQuantity; i++) {
        var classes = 'slider__dot' + (actualSlide === i ? ' active' : '');

        dots.push((
          <div
            className={classes}
            key={i}
            onClick={this.onPaginationDotClick.bind(null, i)}
          />
        ));
      }

      return dots;
    };

    goToSlide = (index, direction, feature, event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      const nextIndex = checkIndex(index, actualSlides(this.props), this.props.loop);

      this.setState({
        index: nextIndex,
        prevIndex: this.state.index,
      });

      this.props.onChangingSlide && this.props.onChangingSlide(nextIndex, direction, feature);
    };

    getSlidesStyles = () => {
      var fullWidth = this.props.fullWidth;
      var size = fullWidth ? 100 : getElementWidth(this.slide, true);
      var unit = fullWidth ? '%' : 'px';

      var style = {
        left: -1 * this.props.pageSize * this.state.index * size + unit,
        width: this.props.children.length * size + unit,
      };

      if (fullWidth) {
        delete style.width;
      }

      return style;
    };

    onPaginationDotClick = (dotIndex) => {
      var currentIndex = this.state.index;
      var direction;

      if (currentIndex > dotIndex) {
        direction = 'back';
      } else if (currentIndex < dotIndex) {
        direction = 'forward';
      }

      if (direction) {
        this.goToSlide(dotIndex, direction, 'dot');
      }
    };

    setContainerRef = (element) => {
      this.containerElement = element;
    };

    setSlideRef = (element) => {
      this.slide = element;
    };

    renderArrows = () => (
      <div className="slider__arrows">
        {(this.props.loop || this.state.index > 0) &&
          <span
            className="slider__arrow slider__arrow--left"
            onClick={this.goToSlide.bind(null, this.state.index - 1, 'back', 'arrow')}
          />
        }
        {(this.props.loop || this.state.index < actualSlides(this.props) - 1) &&
          <span
            className="slider__arrow slider__arrow--right"
            onClick={this.goToSlide.bind(null, this.state.index + 1, 'forward', 'arrow')}
          />
        }
      </div>
    );

    renderA11yArrowLeft = () => {
      const hasLeftArrow = this.props.loop || this.state.index > 0;

      return (
        <div className="slider__arrows">
          {hasLeftArrow &&
            <button
              className="btn-reset slider__arrow-wrap--left"
              onClick={this.goToSlide.bind(null, this.state.index - 1, 'back', 'arrow')}
            >
              <span aria-hidden="true" className="slider__arrow--left" />
              <span className="visually-hidden">{translate('pli.colorslider.nav.arrow')}</span>
            </button>
          }
        </div>
      );
    };

    renderA11yArrowRight = () => {
      const hasRightArrow = this.props.loop || this.state.index < actualSlides(this.props) - 1;

      return (
        <div className="slider__arrows">
          {hasRightArrow &&
            <button
              className="btn-reset slider__arrow-wrap--right"
              onClick={this.goToSlide.bind(null, this.state.index + 1, 'forward', 'arrow')}
            >
              <span aria-hidden="true" className="slider__arrow--right" />
              <span className="visually-hidden">{translate('pli.colorslider.nav.arrow')}</span>
            </button>
          }
        </div>
      );
    };

    state = {
      index: checkIndex(this.props.selected, actualSlides(this.props), this.props.loop),
      prevIndex: 0,
    };

    render() {
      var props = this.props;
      var setSlideRef = this.setSlideRef;
      var animatedClass = props.animated ? ' slider__slides--transition' : '';
      var sliderContainerClasses = actualSlides(this.props) < 2 ? 'single-page' : '';

      return (
        <div className={`${getModifierClass('slider', props.uiModifier)} slider--${props.uiModifier}-${props.pageSize}`}>
          {props.showArrows && !props.a11yArrows && this.renderArrows()}
          {props.showArrows && props.a11yArrows && !props.showOnlyRightArrow && this.renderA11yArrowLeft()}
          <div className={getModifierClass('slider__container', sliderContainerClasses)} ref={this.setContainerRef}>
            <div ref={this.slider} onTransitionEnd={debounce(() => props.onSlideAnimationEnd && props.onSlideAnimationEnd(this.state.index), 300)} className={'slider__slides' + animatedClass} style={this.getSlidesStyles()}>
              {React.Children.map(props.children, function(child) {
                return React.cloneElement(child, { setRef: setSlideRef });
              })}
            </div>
          </div>
          {props.showArrows && props.a11yArrows && this.renderA11yArrowRight()}
          {props.showDots &&
          <div className="slider__dots">
            {this.getPaginationDots()}
          </div>
          }
        </div>
      );
    }
  }

  module.exports = Slider;
});
