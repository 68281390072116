define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');
  var Section = require('common/components/promotion/section');
  var { map, assign, extend } = require('underscore');

  class PromotionPage extends React.Component {
    static propTypes = {
      sections: PropTypes.array.isRequired,
      type: PropTypes.string.isRequired,
    };

    render() {
      var props = this.props;

      return (
        <div>
          {map(this.props.sections, function(section, idx) {
            var sectionData = assign({}, {
              idx,
              section,
              type: props.type,
              getFittingRoomsCovers: props.getFittingRoomsCovers,
              getRotatorData: props.getRotatorData,
              tracking: extend({
                section: (idx + 1).toString(),
              }, props.tracking),
            });

            return <Section key={idx} {...sectionData} />;
          })}
        </div>
      );
    }
  }

  module.exports = PromotionPage;
});
