import { find } from 'underscore';

const { host } = window.location;
const environments = [
  {
    name: 'production',
    pattern: /^(www|preview)\.[a-z]+\.com$/,
    previewPattern: /^preview\.[a-z]+\.com$/,
    default: true,
  },
  {
    name: 'pqa',
    pattern: /^((www|preview)\.)?pqa\.[a-z]+\.com/,
    previewPattern: /^preview\.pqa\.[a-z]+\.com/,
  },
  {
    name: 'integration',
    pattern: /^(integration(-preview)?|demo)\.[a-z]+\.com/,
    previewPattern: /^integration-preview\.[a-z]+\.com/,
  },
  {
    name: 'gcp-test',
    pattern: /^(test(-preview)?|demo)\.[a-z]+\.com/,
    previewPattern: /^test-preview\.[a-z]+\.com/,
  },
  {
    name: 'gcp-stage',
    pattern: /^(www.stage|stage-preview|preview|stage)\.[a-z]+\.com$/,
    previewPattern: /^preview\.stage\.[a-z]+\.com/,
  },
  {
    name: 'gcp-prod',
    pattern: /^(www|preview|prod)\.[a-z]+\.com$/,
    previewPattern: /^preview\.[a-z]+\.com$/,
    default: true,
  },
  {
    name: 'development',
    pattern: /^(dev\.[a-z]+\.com|localhost)/,
  },
];

const environment = find(environments, env => env.pattern.test(host)) || find(environments, { default: true });
const envName = environment.name;
const isPreview = environment.previewPattern && environment.previewPattern.test(host);

export {
  envName,
  isPreview,
};
