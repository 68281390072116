define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');

  class SkuPricing extends React.Component {
    static propTypes = {
      listPrice: PropTypes.number.isRequired,
      onSale: PropTypes.bool,
      salePrice: PropTypes.number,
    };

    render() {
      return (
        <div className="ui-pl-pricing js-pricing">
          {this.props.onSale &&
          <span className="ui-pl-pricing-sale js-pricing-sale">
            <span className="ui-pl-pricing-label">
              {$.t('sale.tag.dropdown').toLowerCase()}
            </span>
            <span className="ui-pl-pricing-low-price price-sale">
              {' ' + $.l('currency', this.props.salePrice) + ' '}
            </span>
          </span>
          }
          <span className={'ui-pl-pricing-high-price price-' + (this.props.onSale ? 'inactive' : 'retail')}>
            {$.l('currency', this.props.listPrice)}
          </span>
        </div>
      );
    }
  }

  module.exports = SkuPricing;
});
