define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');
  var { default: LazyImage } = require('common/components/lazy-loader-io/lazy-image');

  class PLI extends React.Component {
    static propTypes = {
      getRef: PropTypes.func,
      skus: PropTypes.array.isRequired,
      sku: PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string,
        }),
        url: PropTypes.string,
      }),
    };

    render() {
      var sku = this.props.selectedVariant || this.props.skus[0];

      return (
        <a
          className="pli__wrapper js-pli-wrapper"
          href={sku.url}
          ref={this.props.getRef}
        >
          <LazyImage
            componentProximity={100}
            altText={this.props.title}
            imgSrc={'//content.backcountry.com' + sku.image.url}
            uiClasses="pli__image js-pli-image"
          />
          {this.props.children}
        </a>
      );
    }
  }

  module.exports = PLI;
});
