define(function(require, exports, module) {
  var { getSkuById } = require('common/util/pli-helper');
  var { assign, each, find } = require('underscore');

  var sizes = Object.freeze({
    small: 'small',
    medium: 'medium',
    large: 'large',
  });

  function reduceRoom(data) {
    var roomData = assign({}, data);
    var products = roomData.products || {};

    each(roomData.items, function(row) {
      each(row.items, function(item) {
        var product = products[item.id];

        if (!product) {
          return;
        }

        product.selectedVariant = getSkuById(product, item.selectedVariant);
        product.selectedVariant.image.small = product.selectedVariant.image.url.replace(sizes.medium, sizes.small);
        product.selectedVariant.image.url = product.selectedVariant.image.url.replace(sizes.medium, sizes.large);
      });
    });

    return assign({}, products);
  }

  function reduceProduct(data) {
    if (!data || !data.products || !data.products.length) {
      return [];
    }
    var productsData = [];
    var product = data.products[0];
    var skuColors = [];
    var hasColor = function(skuColor) {
      return this && this.color && this.color.name === skuColor.color.name;
    };

    product.skus.forEach(function(sku) {
      if (!find(skuColors, hasColor, sku)) {
        skuColors.push(sku);
      }
    });

    skuColors.forEach(function(sku) {
      var newProduct = assign({}, product);

      newProduct.id = sku.id;
      newProduct.selectedVariant = sku;
      newProduct.selectedVariant.image.small = sku.image.url;
      newProduct.selectedVariant.image.url = sku.image.url.replace(sizes.medium, sizes.large);
      productsData.push(newProduct);
    });

    return productsData;
  }

  module.exports = {
    reduceProduct,
    reduceRoom,
  };
});
