define(function(require, exports, module) {
  var PropTypes = require('prop-types');
  var React = require('react');
  var cssClassHelper = require('common/util/css-class-helper');
  var translate = require('common/localization').translate;
  var { compact, map, isEmpty } = require('underscore');

  class PromotionSubsection extends React.Component {
    static propTypes = {
      subsections: PropTypes.array.isRequired,
      type: PropTypes.string.isRequired,
      getFittingRoomsCovers: PropTypes.func.isRequired,
    };

    state = {
      covers: [],
      dataReceived: false,
    };

    componentWillUnmount() {
      this._isMounted = false;
    }

    componentDidMount() {
      var self = this;

      this._isMounted = true;

      var fittingRoomIds = map(this.props.subsections, function(subsections) {
        return subsections.fittingRoomId;
      });

      if (isEmpty(compact(fittingRoomIds))) {
        self.setState({
          dataReceived: true,
        });
      } else {
        this.props.getFittingRoomsCovers(fittingRoomIds)
          .then(function(covers) {
            self.setState({
              covers,
              dataReceived: true,
            });
          })
          .fail(function() {
            self.setState({
              dataReceived: true,
            });
          });
      }
    }

    render() {
      var state = this.state;
      var reducedClass = this.props.subsections.length > 5 ? ' sub-cat__nav-wrap--reduced' : '';

      return (
        <div>
          <h3 className={cssClassHelper.getJsClasses('promotion__section-title')}>{translate('shop.by', this.props.type)}</h3>
          <div className={'sub-cat__nav-wrap qa-subcat-wrap' + reducedClass}>
            {map(this.props.subsections, function(subsection, idx) {
              var cover = (!isEmpty(state.covers) && state.covers[idx]) || subsection.defaultImage;

              return (
                <div key={idx} className={cssClassHelper.getJsClasses('sub-cat') + ' qa-subcat'}>
                  <a className={cssClassHelper.getJsClasses('sub-cat__link')} href={subsection.url}>
                    {state.dataReceived ?
                      <img className={cssClassHelper.getJsClasses('sub-cat__image')} src={'//content.' + BC.site.domain + cover} alt={subsection.title} /> :
                      <div className={cssClassHelper.getJsClasses('sub-cat__img-placeholder')} />
                    }
                    <h4 className="sub-cat__title">{subsection.title}</h4>
                    <span className="sub-cat__discount">
                      {translate('label.subcat.discount',
                        subsection.promotionText.prefix,
                        subsection.promotionText.percentageOff,
                        subsection.promotionText.suffix)}
                    </span>
                    <div className="discount-tag">
                      <div className="discount-tag__background" />
                      <div className="discount-tag__content">
                        <span className="discount-tag__prefix">{subsection.promotionBadge && subsection.promotionBadge.prefix || subsection.promotionText.prefix}</span>
                        <span className="discount-tag__text">{subsection.promotionBadge && subsection.promotionBadge.percentageOff || subsection.promotionText.percentageOff}</span>
                        <div className="discount-tag__suffix">
                          <span className="discount-tag__percent">%</span>
                          <span className="discount-tag__suffix-text">{subsection.promotionBadge && subsection.promotionBadge.suffix || subsection.promotionText.suffix}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  module.exports = PromotionSubsection;
});
